import menus from '@/constants/menus'
import Container from '@/components/basic/container'
import Header from '@/components/layout/header'
import Footer from '@/components/layout/footer'

import LoadingProgress from '@/components/basic/loading-progress'

import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

const MobileAppLayout = ({
  children,
  container = { size: null, spacing: null },
}) => {
  return (
    <Container size={container.size} spacing={container.spacing}>
      {children}
    </Container>
  )
}

const Layout = ({
  children,
  showAppLink = true,
  home = false,
}) => {
  const [downloadAppLink, setDownloadAppLink] = useState(showAppLink)
  const [scrollPos, setScrollPos] = useState(0)
  const router = useRouter()
  const iframe = router.query.iframe
  console.log(iframe,'iframeiframe')
  return (
    <>
      
        <LoadingProgress />
      {!iframe && (
        <Header menus={menus} home={home}/>
      )}
      {/* <Container size={container.size} spacing={container.spacing} className="overflow-x-hidden"> */}
        {children}
      {/* </Container> */}
      {!iframe && (
        <Footer/>
      )}
    </>
  )
}

export default function DefaultLayout(props) {
  const router = useRouter()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(router.isReady)
  }, [router.isReady])

  if (!isReady || router?.query?.ma == 1) {
    return (
      <MobileAppLayout {...props} />
    )
  }

  return (
    <Layout {...props} />
  )
}